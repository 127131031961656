

































































































































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import { Quote } from '@/models/quote'
import {
  binderModule,
  brokerageModule,
  cartModule,
  quoteModule,
  underwritersModule,
  checkoutModule
} from '@/store/store-accessor'
import { Product } from '@/models/product'
import SummaryCard from '@/components/quote/summary-card.vue'
import { Policy } from '@/models/policy'
import { AlertCircleIcon, AlertTriangleIcon } from 'vue-feather-icons'
import LoadingIcon from '@/components/loading-icon/index.vue'
import { ROUTES } from '@/router/routes'
import BrokerageMixin from '@/mixins/brokerage-mixin'
import IconBase from '@/components/icon-library/icon-base.vue'
import AgentSupport from '@/components/icon-library/agent-support.vue'
import ReviewLauncherButton from '@/components/review/review-launcher-button.vue'
import ReviewOverlay from '@/components/review/review-overlay.vue'
import QuotesErrorsOverlay from '@/components/quote/quotes-errors-overlay.vue'
import { BillingType, Underwriter } from '@/models/underwriter'
import { TokenNotFoundError } from '@/models/errors'
import SectionWrapper from '@/components/section-wrapper/section-wrapper.vue'
import AgentAlert from '@/components/quote/agent-alert.vue'
import PolicyCart from '@/components/quote/policy-cart/index.vue'
import CalloutBox from '@/components/callout-box/index.vue'
import DrawerPolicyCart from '@/components/quote/drawer/policy-cart.vue'
import LoadableMixin from '@/mixins/loadable-mixin'
import CheckoutActions from '@/components/checkout/actions.vue'
import CreditCardForm from '@/components/checkout/credit-card-form.vue'
import CheckoutLayout from '@/layouts/checkout-layout.vue'
import BillingForm from '@/components/checkout/billing-form.vue'
import { isPolicyBound, isPolicyFailed } from '@/helpers/quote'

@Component({
  name: 'Checkout',
  components: {
    LoadingIcon,
    CreditCardForm,
    SummaryCard,
    CheckoutLayout,
    AlertTriangleIcon,
    AlertCircleIcon,
    IconBase,
    AgentSupport,
    ReviewLauncherButton,
    QuotesErrorsOverlay,
    ReviewOverlay,
    SectionWrapper,
    AgentAlert,
    CheckoutActions,
    PolicyCart,
    CalloutBox,
    DrawerPolicyCart,
    BillingForm
  }
})
export default class Checkout extends Mixins(BrokerageMixin, LoadableMixin) {
  @Prop() id!: string;

  confirmBusinessInformation = false;

  @Watch('quotePolicy')
  onQuotePolicyChanged (policy: Policy) {
    if (isPolicyFailed(policy)) {
      this.hideBtnLoading()
      checkoutModule.setErrorMessage(
        'There was an error processing your payment'
      )
    } else if (isPolicyBound(policy)) {
      this.$router.push({
        name: ROUTES.CHECKOUT_CONFIRMATION,
        params: { id: this.$route.params.id }
      })
    }
  }

  get isDirectBilling () {
    return this.selectedQuote?.billingType === BillingType.Direct
  }

  get isAgencyBilling () {
    return this.selectedQuote?.billingType === BillingType.Brokerage
  }

  get selectedQuote (): Quote | undefined {
    if (!quoteModule.quoteSelections.length) return
    this.hideLoading()

    return quoteModule?.quotesData?.find(
      (quote) => quote.id === quoteModule.quoteSelections[0].quoteId
    )
  }

  get collectPayment () {
    return this.selectedQuote?.collectPayment
  }

  get underwriter (): Underwriter | undefined {
    return underwritersModule.underwriters.find(
      (underwriter) => underwriter.slug === this.selectedQuote?.underwriterSlug
    )
  }

  get quoteProducts (): Product[] {
    // looking at index[0] of the quotes since MVP will only support one product
    return quoteModule.productList.filter(
      (product) => product.id === quoteModule.quotesData[0].productId
    )
  }

  get cartItems () {
    return cartModule.cartItems
  }

  get quotePolicy (): Policy | null {
    return quoteModule.policyData
  }

  get showCart (): boolean {
    return binderModule.toggleCart
  }

  get errorMessage () {
    return checkoutModule.errorMessage
  }

  toggleCart () {
    binderModule.setShowCart()
  }

  async setQuotePendingUpdate (quoteId: string | null) {
    await cartModule.setPendingUpdateQuote(quoteId)
  }

  get quotePendingUpdate (): string | null {
    return cartModule.pendingUpdateQuote
  }

  async created () {
    try {
      await binderModule.checkApplication()
      if (!this.selectedQuote) {
        this.showLoading()
        await quoteModule.checkForPendingQuote({
          id: this.id
        })
      }
    } catch (e: any) {
      if (e instanceof TokenNotFoundError) {
        this.$router.push({ name: ROUTES.LINK_NOT_FOUND })
      } else {
        throw e
      }
    }
  }

  async mounted () {
    window.scrollTo(0, 0)

    document.title = brokerageModule.brokerage?.name
      ? `${brokerageModule.brokerage?.name} | ${this.$route?.meta?.title}`
      : this.$route?.meta?.title
  }

  async confirmCheckout () {
    try {
      this.showBtnLoading()

      await checkoutModule.postPolicy({
        selectedQuote: this.selectedQuote as Quote,
        collectPayment: this.collectPayment ?? false
      })
    } catch (error: any) {
      checkoutModule.handleError(error)
      this.hideBtnLoading()
    }
  }
}
