








































import { Component, Mixins, Prop } from 'vue-property-decorator'
import TextInput from '@/components/controls/text-input/index.vue'
import BrizaMixin from '@/mixins'
import { Address } from '@/models/questionnaire'
import MapSearchBar from '@/components/address/map-search-bar.vue'
import ManualAddress from '@/components/address/manual-address.vue'
import { checkoutModule } from '@/store/store-accessor'
import ManualAddressToggle from '@/components/address/manual-address-toggle.vue'

@Component({
  name: 'BillingForm',
  components: { TextInput, ManualAddressToggle, MapSearchBar, ManualAddress }
})
export default class BillingForm extends Mixins(BrizaMixin) {
  @Prop() controlId!: string

  isManual = false

  get billingAddress () {
    return checkoutModule.billingAddress
  }

  get emailAddress () {
    return checkoutModule.emailAddress
  }

  set emailAddress (email: string) {
    checkoutModule.setEmailAddress(email)
  }

  onAddressChange (currentAddress: Address) {
    checkoutModule.setBillingAddress({
      ...currentAddress,
      ...(currentAddress.unitNumber && {
        secondary: currentAddress.unitNumber
      })
    })
  }

  handleManualClick () {
    this.isManual = !this.isManual
  }
}
