












































































































import { Component, Mixins, Prop } from 'vue-property-decorator'
import { Quote } from '@/models/quote'
import TextInput from '@/components/controls/text-input/index.vue'
import ErrorLabel from '@/components/controls/error-label/index.vue'
import Select from '@/components/controls/select/index.vue'
import { checkoutModule, quoteModule } from '../../store/store-accessor'
import LoadingIcon from '@/components/loading-icon/index.vue'
import BrizaMixin from '@/mixins'
import { brizaCollect } from '@briza/collect'
import LoadableMixin from '@/mixins/loadable-mixin'

@Component({
  name: 'CreditCardForm',
  components: { Select, TextInput, LoadingIcon, ErrorLabel }
})
export default class CreditCardForm extends Mixins(BrizaMixin, LoadableMixin) {
  @Prop() selectedQuote!: Quote;

  hideForm = true;
  errors = {
    name: '',
    expYear: '',
    expMonth: ''
  };

  async mounted () {
    const collect = await brizaCollect({
      environment:
        process.env.VUE_APP_APP_ENV === 'production'
          ? 'live'
          : process.env.VUE_APP_APP_ENV,
      fields: {
        'card-number': '#cc-number .field-space',
        'card-security-code': '#cc-cvc .field-space'
      },
      css: {
        ...this.iframeInputStyle,
        border: '1px solid #222222'
      }
    })

    collect.onReady().then(() => {
      this.hideForm = false
    })

    checkoutModule.setBrizaCollect(collect)
  }

  get creditCard () {
    return checkoutModule.creditCard
  }

  get downPayment () {
    // The cart total will the total down payment of all quotes selected
    return quoteModule.quoteSelections.reduce(
      (downPaymentTotal, currentQuoteSelection) => {
        return downPaymentTotal + currentQuoteSelection.downpayment
      },
      0
    )
  }

  updateExpiryMonths (key: string) {
    this.creditCard.expMonth = key
  }

  updateExpiryYear (key: string) {
    this.creditCard.expYear = key
  }

  currentYear () {
    return new Date().getFullYear()
  }

  get iframeInputStyle () {
    return {
      background: '#FFFFFF',
      boxSizing: 'border-box',
      height: '3.375rem',
      fontSize: '1.25rem',
      lineHeight: '1.688rem',
      borderWidth: '1px',
      borderRadius: '4px',
      padding: '0px 16px',
      '&:focus': {
        border: '2px solid #222222',
        padding: '0 15px'
      },
      '&:hover': {
        border: '2px solid #222222',
        padding: '0 15px'
      },
      '&::placeholder': {
        color: '#454F5B'
      }
    }
  }
}
