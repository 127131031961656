











import { Component, Vue } from 'vue-property-decorator'
import { ArrowLeftIcon } from 'vue-feather-icons'

@Component({
  name: 'CheckoutLayout',
  components: {
    ArrowLeftIcon
  }
})
export default class CheckoutLayout extends Vue {

}
