























































import { cartModule } from '@/store/store-accessor'
import { Component, Prop, Vue } from 'vue-property-decorator'
import LoadingIcon from '@/components/loading-icon/index.vue'

@Component({
  name: 'CheckoutActions',
  components: { LoadingIcon }
})

export default class CheckoutActions extends Vue {
  confirmInformation = false

  @Prop() confirmCheckout!: () => {}
  @Prop({ default: false }) btnLoading!: boolean

  backToQuotes () {
    this.$router.push('quotes')
  }

  get allowConfirm () {
    return cartModule.cartItems.length > 0 && this.confirmInformation
  }

  toggleConfirm () {
    this.confirmInformation = !this.confirmInformation
  }
}
